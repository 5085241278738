import { forkJoin, Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

export class MultiTranslateServerLoader implements TranslateLoader {
  constructor(
    private name: string,
    private paths: string[],
    private http: HttpClient,
    private transferState: TransferState
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const requests = this.paths.map((path) => {
      const url = `${environment.endpoints.i18nUrl}${path}/${lang}.json`;
      return this.http.get(url);
    });

    return new Observable((observer) => {
      forkJoin(requests).subscribe({
        next: (responses) => {
          const mergedTranslations = responses.reduce((acc, jsonData) => {
            return { ...acc, ...jsonData };
          }, {});

          const key: StateKey<any> = makeStateKey<any>(
            `transfer-translate-${this.name}-${lang}`
          );
          this.transferState.set(key, mergedTranslations);

          observer.next(mergedTranslations);
          observer.complete();
        },
        error: (err) => {
          console.error('Error fetching translations:', err);
          observer.error(err);
        },
      });
    });
  }
}

export function translateMultiServerLoaderFactory(
  name: string,
  http: HttpClient,
  transferState: TransferState,
  paths: string[]
) {
  return new MultiTranslateServerLoader(name, paths, http, transferState);
}
