import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
export class TranslateServerLoader implements TranslateLoader {
  constructor(
    private path: string,
    private http: HttpClient,
    private transferState: TransferState
  ) {}

  public getTranslation(lang: string): Observable<any> {
    return new Observable((observer) => {
      const url = `${environment.endpoints.i18nUrl}/${this.path}/${lang}.json`;
      this.http.get(url).subscribe({
        next: (jsonData) => {
          const key: StateKey<number> = makeStateKey<number>(
            `transfer-translate-${this.path}-${lang}`
          );
          this.transferState.set(key, jsonData);

          observer.next(jsonData);
          observer.complete();
        },
      });
    });
  }
}

export function translateServerLoaderFactory(
  path: string,
  http: HttpClient,
  transferState: TransferState
) {
  return new TranslateServerLoader(path, http, transferState);
}
